import React from 'react'
import NavBar from '../components/NavBar'
import { Box, Divider, Stack, Typography } from '@mui/material'
import akLogo from '../assests/AkLogo.png'
import paternOneLogo from '../assests/paternOneLogo.jpg'
export default function AboutUs() {
    return (
        <div>
            <NavBar />
            <br />
            <br />
            <Divider variant="middle" />

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
                mb={6}
            >
                <Box style={{ marginTop: '20px', width: '75vw' }}>
                    <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center' }}>Who We Are</Typography>
                    <Stack direction={'row'} justifyContent={'space-around'} spacing={12}>
                        <Box sx={{ width: '40vw', color: 'black' }} >
                            <img src={akLogo} style={{ marginTop: 25, maxWidth: '450px', marginLeft: '8vw' }}></img>

                        </Box>
                        <Box>
                            <Typography>

                                <p>AK Chemicals is a Kenyan based company that supplies quality chemicals & raw materials across the East African Region.</p>

                                <p>Our customer base spreads across the big industry players to the growing micro, small and medium sized enterprises in the region.</p>

                                <p>We take pride in the quality of our products which are sourced from China, India, Europe, UAE and the Middle East.</p>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>

            </Stack>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
                mb={6}
            >
                <Box style={{ marginTop: '20px', width: '75vw' }}>
                    <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center', marginBottom: '50px' }}>WHAT GUIDES US</Typography>
                    <Stack direction={'column'} justifyContent={'space-around'} >
                        <Box sx={{ color: 'black', textAlign: 'center' }} >
                            <Typography>
                                <Typography sx={{ color: 'rgba(235, 162, 21, 1)', fontSize: '2vw' }}>Our VISION: </Typography>

                                <p>To be the leading and most reliable distributor of quality chemicals & raw
                                    materials in the East African region.</p>
                            </Typography>
                        </Box>
                        <Box sx={{ color: 'black', textAlign: 'center' }}>
                            <Typography>

                                <Typography sx={{ color: 'rgba(54, 145, 220, 1)', fontSize: '2vw' }}>Our MISSION:</Typography>

                                <p>To put the interests of our customers first and be dedicated to providing quality chemicals & raw materials that suit their application and satisfy their needs giving value for money.</p>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>

            </Stack>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
                mb={6}
            >
                <Box style={{ marginTop: '20px', width: '75vw' }}>
                    <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center', marginBottom: '50px' }}>OUR CORE VALUES</Typography>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'} spacing={8}>
                        <Box sx={{ color: 'black', textAlign: 'center', width: '40%' }} >
                            <Typography>
                                <Typography>Integrity: </Typography>

                                <p>We are fair and honest and our reliability is
                                    beyond measure.</p>
                            </Typography>
                            <Typography>
                                <Typography>Commitment To Quality: </Typography>

                                <p>We are dedicated to providing superior and
                                    quality chemicals and raw materials to our
                                    customers that will accelerate growth in their
                                    business.</p>
                            </Typography>
                        </Box>
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="180" viewBox="0 0 12 263" fill="none">
                            <path d="M7 10.1449L11.7735 0.144897L0.226497 0.144897L5 10.1449H7ZM5 252.299L0.226497 262.299H11.7735L7 252.299H5ZM5 9.1449L5 253.299H7L7 9.1449H5Z" fill="#6B440D" />
                        </svg>
                        <Box sx={{ color: 'black', textAlign: 'center', width: '40%' }}>
                            <Typography>
                                <Typography>
                                    <Typography>Customer Focus:</Typography>

                                    <p>We are committed to our customers and we
                                        put them at the center of our operations.</p>
                                </Typography>
                                <Typography>
                                    <Typography>
                                        Our People</Typography>

                                    <p>Our vibrant and highly qualified team
                                        is committed to excellence and quality
                                        performance.</p>
                                </Typography>
                            </Typography>
                        </Box>
                    </Stack>
                </Box>

            </Stack>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Box style={{ marginTop: '20px', width: '75vw' }}>
                    <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center', marginBottom: '50px' }}>OUR PARTNERS</Typography>
                    <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                        <img src={paternOneLogo}></img>
                    </Stack>
                </Box>

            </Stack>
        </div >
    )
}
