import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import akLogo from '../assests/AkLogo.png'
import { Button, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import HamMenu from './HamMenu';
function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function NavBar(props) {
    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar sx={{ backgroundColor: 'white', marginTop: 3, height: '60px' }}>
                    <Toolbar>
                        <Box sx={{ marginLeft: { xs: '0vw', sm: '8vw' } }}>

                            <img src={akLogo} style={{ marginTop: 25, maxWidth: '200px', }}></img>
                        </Box>

                        <Box sx={{ marginLeft: { xs: '0vw', sm: '30vw' }, display: { xs: 'none', md: 'flex' } }}>
                            <Link to='/'><Button>Home</Button></Link>
                            <Link to='/aboutus'><Button>About Us</Button></Link>
                            <Link to='/ourproducts'><Button>Our Products</Button></Link>
                            <Link to='/contactus'><Button>Contact Us</Button></Link>
                        </Box>
                        <Box sx={{ marginLeft: { xs: 'auto' }, display: { xs: 'flex', md: 'none' } }}>
                            <HamMenu />
                        </Box>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
        </React.Fragment >
    );
}
