import React from 'react'
import NavBar from '../components/NavBar'
import { Box, Divider, Stack, Typography } from '@mui/material'
import ProductList from '../components/ProductList'

export default function OurProducts() {
    return (
        <div>
            <NavBar />
            <br />
            <br />
            <Divider variant="middle" />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Box style={{ marginTop: '20px', width: '75vw' }}>
                    <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28, textAlign: 'center' }}>Chemicals and Raw Materials</Typography>
                    <Typography style={{ color: 'black', fontFamily: 'jost', fontSize: 22, textAlign: 'left', marginTop: 10 }}>Discover our extensive range of high-quality industrial chemicals and raw materials, carefully curated for a variety of industries. From Soap & Detergents to Paints & Adhesives, Agrochemicals, Food and Beverages, Cosmetics, and beyond, we're your dependable source for essential ingredients. Whether you're formulating shampoos, paints, agrochemicals, cosmetics, or other products, our offerings provide the foundation you need. Explore our versatile catalogue and find the perfect solution for your industry.</Typography>
                </Box>
            </Stack>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Box style={{ marginTop: '20px', width: '75vw' }}>
                    <ProductList />
                </Box>
            </Stack>

        </div>
    )
}
