import React from 'react';
import logo from './logo.svg';
import './App.css';
import NavBar from './components/NavBar';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import landingPageImg from '../src/assests/landingPageImg.png'
import card_bg from '../src/assests/card_bg.png'

const productBtnList = [
  "Cosmetics",
  "Textiles",
  "Soap and Detergents",
  "Food and Beverages",
  "Paint and Adhesives",
  "Leather and Tanneries",
  "Agrochemical Industries"
];

const latitude = -1.2584847; // Replace with your desired latitude
const longitude = 36.8278661; // Replace with your desired longitude

const googleMapsUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.826517175857!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760523922ff9d9%3A0xc85f21e61521f8d0!2sBig%20Ben!5e0!3m2!1sen!2suk!4v1628601154551!5m2!1sen!2suk`;

function App() {
  return (
    <div className="App">
      <NavBar />
      <br />
      <br />
      <br />
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <img style={{ maxWidth: '80%' }} src={landingPageImg}></img>
        </Box>

        <Box sx={{ width: { xs: '100vw', md: '40vw' }, color: 'white' }}>
          <Typography sx={{ fontFamily: 'Jomhuria', fontSize: { xs: 40, md: 72 } }}>Welcome to <span style={{ color: 'rgba(235, 162, 21, 1)' }}>AK</span> <span style={{ color: 'rgba(54, 145, 220, 1)' }}>Chemicals</span>,</Typography>
          <Typography sx={{ fontFamily: 'Jomolhari', fontSize: { xs: 18, md: 24 } }}>“Your trusted distributor of quality industrial chemicals and raw materials in the East African region.”</Typography>
        </Box>
      </Stack>
      <Stack
        direction={'row'}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Box style={{ marginTop: '100px', width: '75vw' }}>
          <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28 }}>WHAT WE SUPPLY</Typography>
          <Typography style={{ color: 'black', fontFamily: 'jost', fontSize: 22, textAlign: 'left', marginTop: 10 }}>We offer a wide range of quality industrial chemicals & raw materials that are used in the industries listed below among many others:</Typography>

          {/* {
              productBtnList.map((item) => (

                <Grid container spacing={2}>
                  <Grid xs={3}>
                    
                  </Grid>
                </Grid>
              ))
              
            } */}
          <Grid container columns={{ xs: 2, sm: 8, md: 12 }} sx={{ marginTop: '40px', width: { xs: '80vw', lg: '75vw' } }} rowGap={4}>
            {productBtnList.map((item) => (
              <Grid xs={1} sm={4} md={4} display="flex" justifyContent="center" alignItems="center">
                <Paper sx={{
                  maxWidth: '250px', minWidth: { xs: '90%', md: '250px' }, padding: { xs: 1, md: 2 },
                  background: 'linear-gradient(90deg, rgba(235, 162, 21, 0.20) 0.01%, rgba(226, 183, 98, 0.20) 42.53%, rgba(0, 0, 0, 0.00) 98.54%)',


                }}>
                  <Typography sx={{ fontSize: { xs: '12px', md: '18px' }, wordWrap: 'break-word' }}>{item}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>

      </Stack>
      <Stack
        direction={'row'}
        spacing={{ xs: 1, sm: 2, md: 4 }}
        justifyContent={'center'}
        alignContent={'center'}
      >
        <Box style={{ marginTop: '50px', width: { xs: '100vw', lg: '75vw' } }}>
          <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28 }}>HEAD OFFICE</Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={'center'}>
            <Box sx={{ width: { xs: '100vw', lg: '40vw' }, color: 'black' }}>
              <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Office Address:</Typography>
              <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>Valley View Office Park, Block A, 5th Floor - City Park Drive, off Limuru Road, Nairobi</Typography>
              <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Warehouse Address:</Typography>
              <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>Airport North Road off Mombasa Road, Embakasi, Nairobi</Typography>
              <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Contacts</Typography>
              <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>+254 702 941 910 | +254 742 631 370  | +254 737 631 370 </Typography>
              <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Email</Typography>
              <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>info@akchemicals.com</Typography>
            </Box>
            <Box sx={{ width: { xs: '100vw', lg: '40vw' }, color: 'black' }}>
              <iframe
                title="Google Map"
                width="90%"
                height="300"
                loading="lazy"
                allowFullScreen
                src={googleMapsUrl}
              ></iframe>
            </Box>
          </Stack>
        </Box>
      </Stack>

    </div>
  );
}

export default App;
