import React, { useState } from 'react';
import { InputAdornment, Stack, TextField, Typography, Grid, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function InquiryForm() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        companyName: '',
        website: '',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        mobilePhone: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add validation logic here, set errors if needed
        console.log(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Stack spacing={3}>
                        <TextField
                            id="fullName"
                            label="Full Name"
                            value={formData.fullName}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircleIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="email"
                            label="Email"
                            value={formData.email}
                            onChange={handleChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="companyName"
                            label="Company Name (optional)"
                            value={formData.companyName}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="website"
                            label="Website (optional)"
                            value={formData.website}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="streetAddress"
                            label="Street Address (optional)"
                            value={formData.streetAddress}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />


                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Stack spacing={3}>
                        <TextField
                            id="city"
                            label="City"
                            value={formData.city}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="state"
                            label="State"
                            value={formData.state}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="postalCode"
                            label="Postal Code"
                            value={formData.postalCode}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="country"
                            label="Country"
                            value={formData.country}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                        <TextField
                            id="mobilePhone"
                            label="Mobile / Cell Phone"
                            value={formData.mobilePhone}
                            onChange={handleChange}
                            variant="standard"
                            sx={{ backgroundColor: 'white' }}
                            fullWidth
                        />
                    </Stack>
                </Grid>
            </Grid>
            <Button type="submit" variant="contained" color="primary" sx={{ margin: 4 }}>
                Submit
            </Button>
        </form>
    );
}
