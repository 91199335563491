import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Paper, Stack } from '@mui/material';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function ProductList() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider', width: 250, textAlign: 'left' }}
            >

                {
                    // Convert productListObj into an array of categories


                    // Map over the categories to render Tab components
                    categories.map((category, index) => (
                        <Tab key={index} label={category} {...a11yProps(index)} />
                    ))
                }

            </Tabs>

            {categories.map((category, index) => (
                <TabPanel key={index} value={value} index={index}>
                    <Stack spacing={3}>
                        {productListObj[category].map((product, productIndex) => (


                            <Paper key={productIndex} sx={{ padding: 2, background: 'linear-gradient(90deg, rgba(235, 162, 21, 0.20) 0.01%, rgba(226, 183, 98, 0.20) 42.53%, rgba(0, 0, 0, 0.00) 98.54%)' }}
                            >
                                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    {product}
                                    <Button>Inquire More</Button>
                                </Stack>
                            </Paper>

                        ))}
                    </Stack>
                </TabPanel>
            ))}

        </Box>
    );
}

const productListObj = {
    "Cosmetic and Pharmaceutical": [
        "Methyl Paraben",
        "Propyl Paraben",
        "White Oil",
        "Petroleum Jelly",
        "Talc (Cosmetic and Filler Grade)",
        "Lanolin & Its derivatives",
        "Fatty Alcohol",
        "Perfumes",
    ],
    "Soap and Detergent": [
        "Talc (Cosmetic and Filler Grade)",
        "Perfumes",
        "Sodium CMC",
        "Color Speckles",
        "Tinapol Cbsx",
        "S.L.E.S. In Shampoo, formulation of wetting agent in Textile Industry & Fire Fighting Foams.",
        "S.L.S LIQUID In Shampoo & Fire Fighting Foams",
        "S.L.S POWDER In Tooth Paste, Shaving Creams, and Powder Shampoo.",
        "Triethanolamine Lauryl Sulphate (T.L.S) In Baby Shampoo, Bubble bath & in Pigments as emulsifier.",
        "COCODIETHANOLAMIDE As foam booster & viscosity builder in shampoo.",
        "COCOBETAINE In shampoo, as hair conditioner.",
        "COCOMONOETHANOLAMIDE As foam booster & viscosity builder in shampoo.",
        "COCOAMIDOPROPYLBETAINE (C.A.P.B) In shampoo, as hair conditioner",
        "Ammonium Lauryl Ether Sulphate In Shampoo and bubble bath.",
        "Ammonium Lauryl Sulphate It is used mainly for shampoo and formulation of toiletry preparations.",
        "ETHYLENEGLYCOLMONOSTE Rate (EGMS) In Shampoo as pearlising agent.",
        "Unishine 35 (Pearlising agent) In Shampoo & Handwash as cold pearlising agent.",
        "GLYCERYL MONOSTERATE (GMS) In Ice Cream as thickening agent, as emulsifier in confectionery, cosmetic creams & Lotions, Hand Creams & Lotions, and Vanishing Creams.",
        "Menthol Crystals",
        "Glycerine",
        "Coconut Oil",
        "Castor Oil",
        "Benzoic Acid/Sodium Benzoate",
        "Stearic Acid",
    ],
    "Toothpaste": ["Sodium Saccharin"],
    "Creams": ["Niacinamide"],
    "Personal Care": [
        "Steareth 2/20",
        "Ceteareth 20/25",
        "Stearic Acid",
        "Glycerine",
        "Coconut Oil",
        "Castor Oil",
        "Benzoic Acid/Sodium Benzoate",
    ],
    "Food and Leather Industry": [
        "Sodium Sulphide",
        "Sodium Hydrosulphide",
        "Ammonium Bicarbonate",
        "Sodium Bicarbonate",
    ],
    "Paints & Soap Detergent": ["Titanium Dioxide (Rutile / Anatase)"],
    "Textile": ["Acetic Acid", "Hydrochloric Acid"],
    "Tanneries": ["Hydrochloric Acid", "Nitric Acid"],
    "Dyestuffs": [
        "Acid (For Leather And Others)",
        "Direct (For Paper, Textile, Leather)",
        "Basic (Powder & Liquid) (For Papers) – Auromine O, Methyl Violet, Victoria Blue, Bismark Brown, Rhodamine, Malachite Green",
        "Reactives (He, Me, Remazols, Hot, Cold)",
        "Pigments (Organic & Inorganic)-(For Paints, Plastic, Solvent Dyes",
        "Ultramarine Blue (For Rubber, Inks, Plastics, Paints, Laundry Etc.",
        "Ultramarine Violet (Pigment Violet 15)",
        "Otical Brightners",
        "Synthetic Iron Oxide Pigments For Paints, Cement Mixing, Lead Chrome Pigments",
        "Pigment Pastes For Soaps / Detergents / Inks",
    ],
    "Food Confectionaries and Additives": [
        "Milk Powder",
        "Liquid Glucose",
        "Corn Starch",
        "Sugar Syrups",
        "Invert Sugar Syrups",
        "Chesse Powder",
        "Lake Colours",
        "Food Colours",
        "Fruit Powder",
        "Vegatable Powder",
        "Dehydrated Vegetables",
        "Fruit Pulp & Concentrates",
        "Essences & Flavours",
    ],
    "Cosmetic Chemicals": [
        "Emulsifying Wax",
        "Polysorbates",
        "Ceteareths",
        "Ethoxylates",
        "Glycerol Mono Stearates",
        "Iso Propyl Myristrate",
        "Bees Wax",
        "Shampoo Base",
    ],
};
const categories = Object.keys(productListObj);