import React from 'react'
import NavBar from '../components/NavBar'
import { Box, Divider, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material'
import '../App.css';
import Contactus from '../assests/Contactus.png'
import EmailIcon from '@mui/icons-material/Email';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InqueryForm from '../components/InqueryForm';

const latitude = -1.2584847; // Replace with your desired latitude
const longitude = 36.8278661; // Replace with your desired longitude

const googleMapsUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.826517175857!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48760523922ff9d9%3A0xc85f21e61521f8d0!2sBig%20Ben!5e0!3m2!1sen!2suk!4v1628601154551!5m2!1sen!2suk`;

export default function ContactUs() {
    return (
        <div class='Contact'>
            <NavBar />
            <br />
            <br />
            <Divider variant="middle" />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent={'center'}
                alignContent={'center'}
            >
                <Stack
                    direction={'column'}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    justifyContent={'center'}
                    alignContent={'center'}
                    className='formContainer'
                >
                    <Typography style={{ color: 'rgba(107, 68, 13, 1)', fontFamily: 'Jomolhari', fontSize: 28 }}>Reach Out to Us ...</Typography>
                    <InqueryForm />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    justifyContent={'center'}
                    alignContent={'center'}
                    className='mapContainerContact'
                    sx={{ backgroundColor: 'linear-gradient(180deg, rgba(235, 162, 21, 0.74) 0%, rgba(235, 162, 21, 0.00) 100%)' }}
                >
                    <Box sx={{ marginTop: '50px', width: '35vw', backgroundColor: 'linear-gradient(180deg, rgba(235, 162, 21, 0.74) 0%, rgba(235, 162, 21, 0.00) 100%)' }}>
                        <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} >
                            <Box sx={{ width: '35vw', color: 'black' }}>
                                <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Office Address:</Typography>
                                <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>Valley View Office Park, Block A, 5th Floor - City Park Drive, off Limuru Road, Nairobi</Typography>
                                <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Warehouse Address:</Typography>
                                <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>Airport North Road off Mombasa Road, Embakasi, Nairobi</Typography>
                                <Typography sx={{ fontFamily: 'Jomhuria', fontSize: 36, color: 'rgba(54, 145, 220, 1)' }}>Contacts</Typography>
                                <Typography sx={{ fontFamily: 'Jomolhari', fontSize: 16 }}>+254 702 941 910 | +254 742 631 370  | +254 737 631 370 </Typography>
                            </Box>
                            <Box>
                                <iframe
                                    title="Google Map"
                                    width="300"
                                    height="150"
                                    loading="lazy"
                                    allowFullScreen
                                    src={googleMapsUrl}
                                    style={{ borderRadius: 10 }}
                                ></iframe>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </Stack>
        </div>
    )
}
